import { graphql } from 'gatsby';
import * as React from 'react';
import Footer from '../components/global/footer/footer';
import Layout from '../components/global/layout/layout';
import Navbar from '../components/global/navbar/navbar';
import ImpressumContent from '../components/impressum/impressum-content/impressum-content';

export const pageQuery = graphql`
  query ImprintQuery {
    site {
      siteMetadata {
        title
        description
        titleSuffix
        titleDivider
      }
    }
  }
`

export default props => {
  return (
    <Layout title="Impressum"
            description={props.data.site.siteMetadata.description}
            titleSuffix={props.data.site.siteMetadata.titleSuffix}
            titleDivider={props.data.site.siteMetadata.titleDivider}>
        <Navbar/>
        <ImpressumContent/>
        <Footer/>
    </Layout>
  );
}
