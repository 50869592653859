import * as React from 'react';
import Link from '../../global/link/link';

export default props => {
    return (
        <nav className="navbar p-0">
            <div className="container">
                <Link className="navbar-brand" to="/">
                    <img src="/images/christoph-hauke-logo.png" alt="" height="36px"></img>
                </Link>
                <div className="ml-auto">
                    <ul className="list-unstyled m-0">
                        <li>
                            <Link className="unstyled font-weight-bold" to="/">Home</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}
