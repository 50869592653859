import * as React from 'react';
import Link from '../../global/link/link';

export default props => {
  return (
    <section className={`container py-5`}>
      <div className="row">
        <div className="col-12 col-md-10">
          <p>
            Wir freuen uns, dass Sie unsere Webseiten besuchen und bedanken uns für Ihr Interesse
            an unserer Dienstleistung und unseren Webseiten. Der Schutz Ihrer Privatsphäre bei der
            Nutzung unserer Webseiten ist uns wichtig. Daher nehmen Sie bitte nachstehende
            Informationen sowie unsere <Link to="/datenschutz" className="unstyled font-weight-bold">Datenschutzerklärung</Link> zur Kenntnis.
          </p>
          <h1>Impressum</h1>
          <address>
            Christoph Hauke<br></br>
            3Gen Worx<br></br>
            Prinz-Georg-Str. 91<br></br>
            40479 Düsseldorf
          </address>
          <p>
            <abbr title="Umsatzsteuer-Identifikationsnummer gemäß § 27a Umsatzsteuergesetz">USt.-ID</abbr>: DE 291703717
          </p>
          <h2>Kontakt</h2>
          <p>
            <a className="unstyled" href="tel:+492114497227">Tel. +49 211 44 97 227</a><br></br>
            <a className="unstyled" href="fax:+492114497228">Fax +49 211 44 97 228</a><br></br>
            <a className="unstyled" href="mailto:info@christophhauke.de">E-Mail: info@christophhauke.de</a><br></br>
            <a className="unstyled" href="https://www.christophhauke.de" target="_blank" rel="noopener noreferrer">www.christophhauke.de</a>
          </p>
        </div>
      </div>
    </section>
  );
}
